import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { UiProvider } from 'context/ui';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { gaPageView } from '@/lib/ga';
import AnalyticsProvider from '@/analytics/AnalyticsProvider';
import {
    BUILD_ENVIRONMENT,
    CODE_BRANCH,
    CODE_VERSION,
    GOOGLE_TAG_MANAGER_ID,
    HEAP_ANALYTICS,
    IS_PRODUCTION,
    LOGROCKET_ID, MUZOLOGY_API,
    ROLLBAR_TOKEN,
    WEBSITE_URL
} from '@/lib/defaults';
import 'theme/styles.scss';
import 'theme/global.scss';
import 'theme/fonts/monotype.css';
import axios from 'axios';
// import LogRocket from 'logrocket';
// import { GoogleTagManager } from '@next/third-parties/google';
// import { Inter } from '@next/font/google';
// const inter = Inter({ subsets: ['latin'] });
axios.defaults.baseURL = MUZOLOGY_API;


function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    //
    // useEffect(() => {
    //     if (analytics) {
    //         // console.log('identify with rudderStack');
    //         // analytics.identify(' ');
    //         // @ts-ignore
    //         console.log('Page:', pageProps?.data?.page?.internalName);
    //         // @ts-ignore
    //         analytics.page(pageProps?.data?.page?.internalName);
    //     } else {
    //         // console.log('rudderStack is null');
    //     }
    // }, [analytics, pageProps]);


    // setup logrocket
    // useEffect(() => {
    //     // identify with LogRocket on the client side - only in production mode
    //     const anonId = analytics?.getAnonymousId();
    //     console.log('[Analytics] anonymousId = ', anonId);
    //     if (LOGROCKET_ID) {
    //         console.log('[Logrocket] setup LogRocket ', LOGROCKET_ID);
    //         LogRocket.init(LOGROCKET_ID, {
    //             rootHostname: 'muzology.com',
    //         });
    //         console.log('[Heap] setup heap ', window.heap);
    //         if (window && window.heap) {
    //             LogRocket.getSessionURL((sessionURL: string) => {
    //                 console.log('[heap] add user properties', { logRocketSessionUrl: sessionURL });
    //                 window.heap.addUserProperties({ logRocketSessionUrl: sessionURL });
    //             });
    //         }
    //     }
    //
    // }, []);


    // add a Google Analytics page view on route change
    useEffect(() => {
        const handleRouteChange = url => {
            console.log('route change:', url);
            // Heap analytics

            // Google Analytics
            gaPageView(url);

            // rudderstack / audience 11
            if (window.rudderanalytics) {
                console.log('[Rudderstack] page = ', url);
                // @ts-ignore
                if (window.rudderanalytics.page) {
                    // @ts-ignore
                    window.rudderanalytics.page(url);
                }
                // @ts-ignore
                window.rudderanalytics.page(url);
            }
        };

        // subscribe to route changes
        router.events.on('routeChangeComplete', handleRouteChange);

        // unsubscribe from route changes on component unmount
        return () => router.events.off('routeChangeComplete', handleRouteChange);
    }, [router.events]);

    return (
        <>
            <DefaultSeo
                // canonical='https://www.muzology.com/'
                openGraph={{
                    type: 'website', locale: 'en_IE', siteName: 'Muzology', url: 'https://www.muzology.com/'
                }}
                twitter={{
                    handle: '@MuzologyEdu', site: '@MuzologyEdu', cardType: 'summary_large_image'
                }}
            />

            {GOOGLE_TAG_MANAGER_ID && <Script id='gtm' strategy='afterInteractive'>
                {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
                    `}
            </Script>}

            {/*
            {HEAP_ANALYTICS && <Script id='heap'>{`
                    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                    heap.load("${HEAP_ANALYTICS}");
            `}</Script>}
            */}

            <UiProvider>
                <AnalyticsProvider pageProps={pageProps}>
                    <Component {...pageProps} />
                </AnalyticsProvider>
            </UiProvider>

            {/*<VercelAnalytics />*/}
        </>

    );
}

export default MyApp;
